import React from "react";
import Navbar from './components/navbar'
import Logo from './components/logo'

function App() {
  return (
    <>
      <Navbar/>
      <Logo/>
    </>
  );
}

export default App;
